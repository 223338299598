// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-apartments-classicapartment-index-js": () => import("./../../../src/pages/apartments/classicapartment/index.js" /* webpackChunkName: "component---src-pages-apartments-classicapartment-index-js" */),
  "component---src-pages-apartments-classicsingle-index-js": () => import("./../../../src/pages/apartments/classicsingle/index.js" /* webpackChunkName: "component---src-pages-apartments-classicsingle-index-js" */),
  "component---src-pages-apartments-deluxeapartment-index-js": () => import("./../../../src/pages/apartments/deluxeapartment/index.js" /* webpackChunkName: "component---src-pages-apartments-deluxeapartment-index-js" */),
  "component---src-pages-apartments-doubleroom-index-js": () => import("./../../../src/pages/apartments/doubleroom/index.js" /* webpackChunkName: "component---src-pages-apartments-doubleroom-index-js" */),
  "component---src-pages-apartments-superior-index-js": () => import("./../../../src/pages/apartments/superior/index.js" /* webpackChunkName: "component---src-pages-apartments-superior-index-js" */),
  "component---src-pages-en-apartments-classicapartment-index-js": () => import("./../../../src/pages/en/apartments/classicapartment/index.js" /* webpackChunkName: "component---src-pages-en-apartments-classicapartment-index-js" */),
  "component---src-pages-en-apartments-classicsingle-index-js": () => import("./../../../src/pages/en/apartments/classicsingle/index.js" /* webpackChunkName: "component---src-pages-en-apartments-classicsingle-index-js" */),
  "component---src-pages-en-apartments-deluxeapartment-index-js": () => import("./../../../src/pages/en/apartments/deluxeapartment/index.js" /* webpackChunkName: "component---src-pages-en-apartments-deluxeapartment-index-js" */),
  "component---src-pages-en-apartments-doubleroom-index-js": () => import("./../../../src/pages/en/apartments/doubleroom/index.js" /* webpackChunkName: "component---src-pages-en-apartments-doubleroom-index-js" */),
  "component---src-pages-en-apartments-superior-index-js": () => import("./../../../src/pages/en/apartments/superior/index.js" /* webpackChunkName: "component---src-pages-en-apartments-superior-index-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

